class BaseService {
    protected token: string
    protected api_url: string

    constructor(token: string) {
        // ensure token format
        token = token.replace('Bearer ', '')
        this.token = token
        this.api_url = process.env.REACT_APP_API_URL || 'https://api.ambientlabs.io'
    }

    public getToken(): string {
        return this.token
    }

    public setToken(token: string): void {
        this.token = token
    }
}

export default BaseService
