import React, { useEffect, useState } from 'react'
import { Routes, Route, useNavigationType, useLocation } from 'react-router-dom'
import LoginPage from './pages/LoginPage'
import HomePage from './pages/HomePage'
import RootPage from './pages/RootPage'
import SignupPage from './pages/SignupPage'
import NodesPage from './pages/NodesPage'
import ClustersPage from './pages/ClustersPage'
import ApplicationsPage from './pages/applications_page/ApplicationsPage'
import NodeInfoPage from './pages/NodeInfoPage'
import AppInfoPage from './pages/AppInfoPage'
// import ClusterInfoPage from './pages/ClusterInfoPage'
import PreferencesPage from './pages/PreferencesPage'
import SettingsPage from './pages/SettingsPage'
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { lightTheme, darkTheme } from './components/Themes'
import { Auth0Provider } from '@auth0/auth0-react'
import { useTheme } from './ThemeContext'
import RegistriesPage from './pages/RegistriesPage'
import UsersAndTokensPage from './pages/UsersAndTokensPage/UsersAndTokensPage'

const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN || ''
const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID || ''

function App() {
    const action = useNavigationType()
    const location = useLocation()
    const pathname = location.pathname
    const { themeMode } = useTheme() // Using useTheme
    console.log('Current theme in App:', themeMode)

    useEffect(() => {
        if (action !== 'POP') {
            window.scrollTo(0, 0)
        }
    }, [action, pathname])

    useEffect(() => {
        let title = ''
        let metaDescription = ''

        switch (pathname) {
            case '/':
                title = ''
                metaDescription = ''
                break
        }

        if (title) {
            document.title = title
        }

        if (metaDescription) {
            const metaDescriptionTag: HTMLMetaElement | null =
                document.querySelector('head > meta[name="description"]')
            if (metaDescriptionTag) {
                metaDescriptionTag.content = metaDescription
            }
        }
    }, [pathname])

    return (
        <div key={themeMode}>
            {' '}
            {/* Force re-render when themeMode changes */}
            <Auth0Provider
                domain={auth0Domain}
                clientId={auth0ClientId}
                authorizationParams={{
                    redirect_uri: `${window.location.origin}/home`,
                }}
                onRedirectCallback={(appState, user) => {
                    // console.log('onRedirectCallback')
                    // console.log('appState: ', appState)
                    // console.log('user: ', user)
                }}
            >
                <MUIThemeProvider
                    theme={themeMode === 'light' ? lightTheme : darkTheme}
                >
                    <CssBaseline />
                    <Routes>
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/home" element={<HomePage />} />
                        <Route path="/" element={<RootPage />} />
                        <Route path="/signup" element={<SignupPage />} />
                        <Route path="/nodes" element={<NodesPage />} />
                        <Route path="/nodes/*" element={<NodeInfoPage />} />
                        <Route path="/apps/*" element={<AppInfoPage />} />
                        <Route
                            path="/registries"
                            element={<RegistriesPage />}
                        />
                        <Route path="/clusters" element={<ClustersPage />} />
                        {/* <Route
                            path="/clusters/*"
                            element={<ClusterInfoPage />}
                        /> */}
                        <Route path="/apps" element={<ApplicationsPage />} />
                        <Route
                            path="/preferences"
                            element={<PreferencesPage />}
                        />
                        <Route path="/settings" element={<SettingsPage />} />
                        <Route
                            path="/users-and-tokens"
                            element={<UsersAndTokensPage />}
                        />
                    </Routes>
                </MUIThemeProvider>
            </Auth0Provider>
        </div>
    )
}

export default App
