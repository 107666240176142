import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TableFooter,
    TablePagination,
    TableSortLabel,
    IconButton,
    Box,
    TextField,
    InputAdornment,
    Tooltip,
    ButtonGroup,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    Snackbar,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import TokenService from '../../libs/ambient_api/TokenService';
import { Token, GetTokenQuery } from '../../types/Token';
import { formatDistanceToNow } from 'date-fns';
import DeleteIcon from '@mui/icons-material/Delete';

interface TokensTableProps {
    token: string;
    externalRefreshToggle?: boolean;
}

const TokensTable: React.FC<TokensTableProps> = ({ token, externalRefreshToggle }) => {
    const [tokens, setTokens] = useState<Token[]>([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [totalTokens, setTotalTokens] = useState(0);
    const [order, setOrder] = useState<'asc' | 'desc'>('asc');
    const [orderBy, setOrderBy] = useState<string>('description');
    const [search, setSearch] = useState('');
    const [searchClicked, setSearchClicked] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const tokenService = new TokenService(token);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteTokenId, setDeleteTokenId] = useState<number | null>(null);

    // Fetch tokens when the component mounts
    useEffect(() => {
        const fetchTokens = async () => {
            try {
                const offset = page * rowsPerPage;
                const data = await tokenService.getTokens(
                    {
                        limit: rowsPerPage,
                        offset,
                        order_by: orderBy,
                        order,
                        search: search || undefined,
                        tombstoned: false,
                    } as GetTokenQuery
                );
                setTokens(data.results);
                setTotalTokens(data.count); // Assuming the API returns the total count of tokens
            } catch (error) {
                console.error('Failed to fetch tokens:', error);
            }
        };

        fetchTokens();
    }, [page, rowsPerPage, order, orderBy, refresh, externalRefreshToggle]);

    // Handle change in search
    useEffect(() => {
        setPage(0);
        setRefresh(!refresh);
    }, [search]);

    const handleSort = (key: keyof Token) => {
        const isAsc = orderBy === key && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(key);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Helper function to format date in a human-friendly way
    const formatExpiryDate = (date: string) => {
        return formatDistanceToNow(new Date(date), { addSuffix: true });
    };

    const ConfirmDeleteDialog = ({ open, onClose, onConfirm }: { open: boolean; onClose: () => void; onConfirm: () => void }) => (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete this token? This action cannot be undone.
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
                <ButtonGroup variant="text" aria-label="actions" style={{ padding: '10px' }}>
                    <IconButton color="primary" onClick={onClose}>
                        Cancel
                    </IconButton>
                    <IconButton color="secondary" onClick={onConfirm}>
                        Delete
                    </IconButton>
                </ButtonGroup>
            </DialogActions>
        </Dialog>
    );

    const handleDeleteButtonClick = (tokenId: number) => {
        const deleteToken = async () => {
            try {
                await tokenService.deleteToken(tokenId);
                setOpenSnackbar(true);
                setRefresh(!refresh);
            } catch (error) {
                console.error('Failed to delete token:', error);
            }
        }
        deleteToken();
        setOpenDeleteDialog(false);
    };

    return (
        <TableContainer component={Paper} sx={{ width: { xs: '500px', md: '800px' } }}>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={() => setOpenSnackbar(false)}
                message="Token deleted successfully"
            />
            <ConfirmDeleteDialog
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
                onConfirm={() => handleDeleteButtonClick(deleteTokenId!)} // Pass the token ID here
            />
            <Table aria-label="tokens table">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'description'}
                                direction={orderBy === 'description' ? order : 'asc'}
                                onClick={() => handleSort('description')}
                            >
                                Description
                            </TableSortLabel>
                            {
                                searchClicked ? (
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <TextField
                                            value={search}
                                            onChange={(e) => setSearch(e.target.value)}
                                            placeholder="Search..."
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            sx={{ color: 'text.secondary' }}
                                                            onClick={() => {
                                                                setSearchClicked(false);
                                                            }}
                                                        >
                                                            <SearchIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Box>
                                ) : (
                                    <IconButton
                                        sx={{ color: 'text.secondary' }}
                                        onClick={() => setSearchClicked(true)}
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                )
                            }
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'expires_at'}
                                direction={orderBy === 'expires_at' ? order : 'asc'}
                                onClick={() => handleSort('expires_at')}
                            >
                                Expires At
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'uid'}
                                direction={orderBy === 'uid' ? order : 'asc'}
                                onClick={() => handleSort('uid')}
                            >
                                UID
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            Actions
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tokens.map((token) => (
                        <TableRow key={token.id}>
                            <TableCell component="th" scope="row">
                                {token.description}
                            </TableCell>
                                <Tooltip title={token.expires_at}>
                            <TableCell>

                                {formatExpiryDate(token.expires_at)}
                            </TableCell>
                                </Tooltip>
                            <TableCell>{token.uid}</TableCell>
                            <TableCell>
                                <ButtonGroup variant="text" aria-label="actions">
                                    <Tooltip title="Delete Token">
                                        <IconButton
                                            color="secondary"
                                            onClick={() => {
                                                setDeleteTokenId(token.id);
                                                setOpenDeleteDialog(true);
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                    </ButtonGroup>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            colSpan={4}
                            count={totalTokens}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
};

export default TokensTable;