import {
    Node,
    DeviceAuthResponse,
    NodePanelData,
    TokenResponse,
} from '../../types/TechnicalTypes'
import { HttpError } from '../../types/Errors'
import { CreateNodeRequest } from '../../types/ApiRequests'
import { stringify } from 'json5'
import { WebSocketSession } from '../../types/TechnicalTypes'

interface GetNodesResponse {
    count: number
    results: Node[]
    timestamp: string
}

interface NodeOptions {
    node_ids?: number[]
    node_tags?: string[]
    node_names?: string[]
}

interface ClusterOptions {
    cluster_ids?: number[]
    cluster_tags?: string[]
    cluster_names?: string[]
}

interface RunCommandRequest {
    command: string[]
    timeout: number
    node_options?: NodeOptions
    cluster_options?: ClusterOptions
    store_output: boolean
}

export interface RunCommandResponse extends RunCommandRequest {
    id: number
    timestamp: string
}

export interface RunCommandOutput {
    command_id: number
    node_id: number
    status: string
    output?: string
    error?: string
}

export interface GetCommandOutputQuery {
    method: string
    query: object
}

export class NodeService {
    private ensureTokenFormat(token: string): string {
        return token.startsWith('Bearer ') ? token.slice(7) : token
    }

    public async patchNode(
        token: string,
        nodeId: number,
        node: Partial<Node>
    ): Promise<Node> {
        const apiUrl = process.env.REACT_APP_API_URL
        token = this.ensureTokenFormat(token)
        const response = await fetch(`${apiUrl}/nodes/${nodeId}`, {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: '*/*',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(node),
        })

        if (!response.ok) {
            throw new HttpError(stringify(response.json()), response.status)
        }

        const parsedResponse: Node = await response.json()
        return parsedResponse
    }

    public async putNode(
        token: string,
        nodeId: number,
        node: Node
    ): Promise<Node> {
        const apiUrl = process.env.REACT_APP_API_URL
        token = this.ensureTokenFormat(token)
        const response = await fetch(`${apiUrl}/nodes/${nodeId}`, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: '*/*',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(node),
        })

        if (!response.ok) {
            throw new HttpError(stringify(response.json()), response.status)
        }

        const parsedResponse: Node = await response.json()
        return parsedResponse
    }

    public async deleteNode(token: string, nodeId: number): Promise<void> {
        const apiUrl = process.env.REACT_APP_API_URL
        token = this.ensureTokenFormat(token)
        const response = await fetch(`${apiUrl}/nodes/${nodeId}`, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: '*/*',
            },
        })

        if (!response.ok) {
            throw new HttpError(stringify(response.json()), response.status)
        }
    }

    public async requestNewDeviceAuth(
        token: string,
        nodeId: number
    ): Promise<TokenResponse> {
        token = this.ensureTokenFormat(token)
        const apiUrl = process.env.REACT_APP_API_URL
        const data = {
            node_id: nodeId,
            request_type: 'node_refresh_token',
            token_type: 'refresh',
        }
        const response = await fetch(`${apiUrl}/auth/token-mgmt`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: '*/*',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })

        if (!response.ok) {
            throw new HttpError(stringify(response.json()), response.status)
        }

        const parsedResponse: TokenResponse = await response.json()
        return parsedResponse
    }

    public async requestTerminalSession(
        token: string,
        ownerId: string,
        nodeId: string
    ): Promise<WebSocketSession> {
        token = this.ensureTokenFormat(token)
        const apiUrl = process.env.REACT_APP_API_URL
        const response = await fetch(`${apiUrl}/nodes/${nodeId}/terminal`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: '*/*',
                'X-Owner-Id': ownerId,
            },
        })

        if (!response.ok) {
            throw new HttpError(stringify(response.json()), response.status)
        }

        const parsedResponse: WebSocketSession = await response.json()
        return parsedResponse
    }

    public async createNode(
        token: string,
        node: CreateNodeRequest
    ): Promise<Node> {
        const apiUrl = process.env.REACT_APP_API_URL
        token = this.ensureTokenFormat(token)
        console.log('Creating node: ', node)
        console.log('Token: ', token)
        const response = await fetch(`${apiUrl}/nodes`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: '*/*',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(node),
        })

        if (!response.ok) {
            if (response.status === 401) {
                throw new HttpError('Unauthorized', 401)
            }
            throw new HttpError(stringify(response.json()), response.status)
        }

        const parsedResponse: Node = await response.json()
        return parsedResponse
    }

    private async fetchNodes(
        token: string,
        sort_by: string,
        order: string,
        status = '',
        name_starts_with = ''
    ): Promise<Response> {
        const apiUrl = process.env.REACT_APP_API_URL
        let fullUrl = `${apiUrl}/nodes?sort_by=${sort_by}&order=${order}`
        // console.log(
        //     'NodeService.fetchNodes - inputs',
        //     token,
        //     sort_by,
        //     order,
        //     status
        // )
        if (status) {
            fullUrl += `&status=${status}`
        }
        if (name_starts_with) {
            fullUrl += `&name_starts_with=${name_starts_with}`
        }
        const response = await fetch(fullUrl, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: '*/*',
            },
        })
        return response
    }

    public async getNodes(
        token: string,
        sort_by = 'timestamp',
        order = NodeOrder.desc,
        status: any = null,
        name_starts_with = ''
    ): Promise<Node[]> {
        const formattedToken = this.ensureTokenFormat(token)
        const response = await this.fetchNodes(
            formattedToken,
            sort_by,
            order === NodeOrder.asc ? 'asc' : 'desc',
            status,
            name_starts_with
        )

        if (!response.ok) {
            if (response.status === 401) {
                throw new HttpError('Unauthorized', 401)
            }
            throw new Error(response.statusText)
        }

        const parsedResponse: GetNodesResponse = await response.json()
        const nodes = (await parsedResponse.results) as Node[]
        // console.log('Got nodes: ', nodes)
        return nodes as Node[]
    }

    public async getNode(token: string, nodeId: number): Promise<Node | null> {
        const apiUrl = process.env.REACT_APP_API_URL
        token = this.ensureTokenFormat(token)
        const response = await fetch(`${apiUrl}/nodes/${nodeId}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: '*/*',
            },
        })

        if (!response.ok) {
            if (response.status === 401) {
                throw new HttpError('Unauthorized', 401)
            }
            console.log('Error: ', response.statusText)
            throw new Error(response.statusText)
        }

        if (response.status === 404) {
            return null
        }

        const parsedResponse: Node = await response.json()
        return parsedResponse as Node
    }

    public async getNodeByName(token: string, name: string): Promise<Node> {
        const nodes = await this.getNodes(
            token,
            'timestamp',
            NodeOrder.desc,
            null
        )
        const node = nodes.find((node) => node.name === name)
        if (!node) {
            throw new Error(`No node found with name ${name}`)
        }
        return node
    }

    public async getNodesPanelData(token: string): Promise<NodePanelData> {
        const apiUrl = process.env.REACT_APP_API_URL
        // apiUrl = apiUrl?.includes('localhost')
        //     ? apiUrl
        //     : apiUrl!.replace('http:', 'https:')
        token = this.ensureTokenFormat(token)
        const fullUrl = `${apiUrl}/nodes/panel_data/`
        console.log(`Getting nodes panel data from ${fullUrl}`)
        const response = await fetch(`${apiUrl}/nodes/panel_data`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: '*/*',
                'Access-Control-Allow-Origin': '*',
            },
        })
        console.log('Response: ', response)

        if (!response.ok) {
            throw new HttpError(stringify(response.json()), response.status)
        }

        const parsedResponse: NodePanelData = await response.json()
        return parsedResponse
    }

    public async searchNodesByName(
        token: string,
        name: string
    ): Promise<Node[]> {
        const nodes = await this.getNodes(
            token,
            'timestamp',
            NodeOrder.desc,
            null
        )
        const filteredNodes = nodes.filter((node) => node.name.includes(name))
        return filteredNodes
    }

    public async getCommandOutputWebsocket(
        command_id: number,
        onMessage: (event: MessageEvent) => void,
        token: string
    ): Promise<null> {
        console.log('Getting command output websocket')
        const apiUrl = process.env.REACT_APP_API_URL
        const ws = new WebSocket(`${apiUrl}/commands/ws/`)

        ws.onopen = () => {
            console.log('WebSocket connection opened')
            ws.send(token)
            console.log('Sent token')
            const query = {
                method: 'GET',
                query: {
                    id: command_id,
                },
            }
            ws.send(JSON.stringify(query))
        }

        ws.onmessage = (event) => {
            console.log('WebSocket message received:', event.data)
            onMessage(event)
        }

        ws.onerror = (error) => {
            console.error('WebSocket error:', error)
        }

        ws.onclose = () => {
            console.log('WebSocket connection closed')
        }

        return null
    }

    public async runCommand(
        token: string,
        nodeIds: number[],
        command: string[]
    ): Promise<RunCommandResponse> {
        const apiUrl = process.env.REACT_APP_API_URL
        token = this.ensureTokenFormat(token)

        const data: RunCommandRequest = {
            command: command,
            timeout: 60,
            node_options: { node_ids: nodeIds },
            store_output: true,
        }

        console.log('Running command: ', data)

        const response = await fetch(`${apiUrl}/commands/`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: '*/*',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })

        console.log('Response: ', response)

        if (!response.ok) {
            throw new HttpError(stringify(response.json()), response.status)
        }

        const parsedResponse: RunCommandResponse = await response.json()
        return parsedResponse
    }

    public async getCommandOutput(
        token: string,
        commandId: number,
        nodeId: number | null
    ) {
        const apiUrl = process.env.REACT_APP_API_URL
        token = this.ensureTokenFormat(token)
        let fullUrl = `${apiUrl}/commands/${commandId}/output`
        if (nodeId) {
            fullUrl += `?node_id=${nodeId}`
        }
        const response = await fetch(fullUrl, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: '*/*',
            },
        })

        if (!response.ok) {
            throw new HttpError(stringify(response.json()), response.status)
        }

        const parsedResponse = await response.json()
        return parsedResponse
    }
}

export enum NodeStatusFilter {
    active = 'active',
    pending = 'pending',
    error = 'error',
}

export enum NodeOrder {
    asc = 'asc',
    desc = 'desc',
}

export interface QueryParams {
    status: NodeStatusFilter | null
    sort_by: string
    order: NodeOrder
    name_starts_with: string
}
