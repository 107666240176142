import React, { useState } from 'react'
import {
    Button,
    Grid,
    Typography,
    Paper,
    Box,
    IconButton,
    AppBar,
    Chip,
    Tooltip,
    ButtonGroup,
    Card,
    Modal,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
    FormHelperText,
    CircularProgress,
    Backdrop,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    Checkbox,
    FormControlLabel,
} from '@mui/material'

import TokenService from '../../libs/ambient_api/TokenService'

interface CreateTokenModalProps {
    open: boolean
    handleClose: () => void
    token: string
    setAccessToken: (token: string) => void
    setRefreshToken: (token: string) => void
    openNewTokenModal: () => void
}

const CreateTokenModal: React.FC<CreateTokenModalProps> = ({ open, handleClose, token, setAccessToken, setRefreshToken, openNewTokenModal }) => {
    const [duration, setDuration] = useState<number | string>('')
    const [description, setDescription] = useState('')
    const tokenService = new TokenService(token)

    const handleCreate = () => {
        if (duration === '' || description === '') {
            alert('Please fill in all fields')
            return
        }
        if (isNaN(Number(duration))) {
            alert('Duration must be a number')
            return
        }
        if (Number(duration) <= 0) {
            alert('Duration must be greater than 0')
            return
        }
        if (description.length < 5) {
            alert('Description must be at least 5 characters long')
            return
        }
        if (description.length > 100) {
            alert('Description must be less than 100 characters long')
            return
        }
        
        const fetchData = async () => {
            try {
                const response = await tokenService.createToken(
                    description,
                    Number(duration)
                )
                console.log('Token created successfully:', response)
                setAccessToken(response.access_token)
                setRefreshToken(response.refresh_token)
            }
            catch (error) {
                console.error('Error creating token:', error)
            }
        }
        fetchData()
        setDuration('')
        setDescription('')

        handleClose()
        openNewTokenModal()
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle>Create Token</DialogTitle>
            <DialogContent>
                <TextField
                    label="Duration"
                    type="number"
                    value={duration}
                    onChange={(e) => setDuration(e.target.value)}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                />
                <TextField
                    label="Description"
                    type="text"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                />
            </DialogContent>
            <DialogActions style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleCreate} variant="contained" color="primary">
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CreateTokenModal