import {
    Button,
    Grid,
    Typography,
    Paper,
    Box,
    IconButton,
    AppBar,
    Chip,
    Tooltip,
    ButtonGroup,
    Card,
} from '@mui/material'
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import RefreshIcon from '@mui/icons-material/Refresh';
import UsersTable from './UsersTable';

interface UsersPanelProps {
    token: string
}

const UsersPanel: React.FC<UsersPanelProps> = ({token}) => {
    return (
        <Paper
            elevation={5}
            sx={{ 
                padding: '20px',
                width: '100%',
                margin: 'auto',
                minHeight: '300px',
            }}
        >

            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
            >
                <Grid item xs={12}>
                    <Grid container direction="row" justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h6">Users</Typography>
                        </Grid>
                        <Grid item>
                            <Box
                                sx={{
                                    width: {
                                        xs: '300px', // for small screens
                                        md: '600px', // for medium and larger screens
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<PersonAddIcon />}
                            >
                                Add User
                            </Button>
                        </Grid>
                        <Grid item>
                            <Tooltip title="Refresh">
                                <IconButton
                                    sx={{ color: 'text.primary' }}
                                >
                                    <RefreshIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <UsersTable token={token}/>
                </Grid>

            </Grid>

        </Paper>
    )
}

export default UsersPanel