import React from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, IconButton, Grid, Tooltip, Button, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface NewTokenModalProps {
    open: boolean;
    handleClose: () => void;
    accessToken: string;
    refreshToken: string;
}

const NewTokenModal: React.FC<NewTokenModalProps> = ({ open, handleClose, accessToken, refreshToken }) => {
    const handleCopy = (token: string) => {
        navigator.clipboard.writeText(token).then(() => {
            // Optionally show a success message or feedback here
            console.info('Token copied to clipboard');
        });
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle>New Tokens</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} alignItems="flex-end">
                    <Grid item xs={12} padding={2}>
                        <Typography variant="body2" align="left">
                            Save these tokens securely! Once you close this dialog, you won't be able to see them again.
                        </Typography>
                    </Grid>
                    <Grid item xs={11}>
                        <TextField
                            label="Access Token"
                            value={accessToken}
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                            margin="dense"
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <Tooltip title="Copy Access Token">
                            <IconButton 
                                onClick={() => handleCopy(accessToken)} 
                                sx={{ color: 'text.primary', bottom: '10px' }}
                            >
                                <ContentCopyIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={11}>
                        <TextField
                            label="Refresh Token"
                            value={refreshToken}
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                            margin="dense"
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <Tooltip title="Copy Refresh Token">
                            <IconButton 
                                onClick={() => handleCopy(refreshToken)} 
                                sx={{ color: 'text.primary', bottom: '10px' }}
                            >
                                <ContentCopyIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center" style={{ marginTop: '16px' }}>
                    <Button variant="contained" color="primary" onClick={handleClose}>
                        Close
                    </Button>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default NewTokenModal;