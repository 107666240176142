import {
    Button,
    Grid,
    Typography,
    Paper,
    Box,
    IconButton,
    Snackbar
} from '@mui/material'
import TokensTable from './TokensTable'
import CreateTokenModal from './CreateTokenModal'
import NewTokenModal from './NewTokenModal'
import { useState } from 'react'
import RefreshIcon from '@mui/icons-material/Refresh';
import TokenIcon from '@mui/icons-material/Token';

interface TokenPanelProps {
    token: string
}

const TokensPanel: React.FC<TokenPanelProps> = ({token}) => {
    const [displayCreateTokenModal, setDisplayCreateTokenModal] = useState(false)
    const [displayNewTokenModal, setDisplayNewTokenModal] = useState(false)
    const [accessToken, setAccessToken] = useState('')
    const [refreshToken, setRefreshToken] = useState('')
    const [refresh, setRefresh] = useState(false)
    const [displayRefreshSnackbar, setDisplayRefreshSnackbar] = useState(false)

    return (
        <Paper
            elevation={5}
            sx={{ 
                padding: '20px',
                width: '100%',
                margin: 'auto',
                minHeight: '300px',
            }}
        >
            <CreateTokenModal
                open={displayCreateTokenModal}
                handleClose={() => {
                    setDisplayCreateTokenModal(false)
                    setAccessToken('')
                    setRefreshToken('')
                    setRefresh(!refresh)
                }}
                token={token}
                setAccessToken={setAccessToken}
                setRefreshToken={setRefreshToken}
                openNewTokenModal={() => {
                    setDisplayNewTokenModal(true)
                }}
            />
            <NewTokenModal
                open={displayNewTokenModal}
                handleClose={() => setDisplayNewTokenModal(false)}
                accessToken={accessToken}
                refreshToken={refreshToken}
            />
            <Snackbar
                open={displayRefreshSnackbar}
                autoHideDuration={3000}
                onClose={() => setDisplayRefreshSnackbar(false)}
                message="Data refreshed successfully!"
            />

            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
            >
                <Grid item xs={12}>
                    <Grid container direction="row" justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h6">Tokens</Typography>
                        </Grid>
                        <Grid item>
                            <Box
                                sx={{
                                    width: {
                                        xs: '270px', // for small screens
                                        md: '570px', // for medium and larger screens
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                // startIcon={<PersonAddIcon />}
                                onClick={() => {
                                    setDisplayCreateTokenModal(true)
                                }}
                                startIcon={<TokenIcon />}
                            >
                                Add Token
                            </Button>
                            <IconButton
                                onClick={() => {
                                    setRefresh(!refresh)
                                    setDisplayRefreshSnackbar(true)
                                }
                                }
                                sx={{ color: 'text.primary', marginLeft: '10px' }}
                            >
                                <RefreshIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TokensTable token={token} externalRefreshToggle={refresh} />
                </Grid>
            </Grid>
        </Paper>
    )
}

export default TokensPanel
