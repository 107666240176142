import BaseService from "./BaseService";
import { Token, GetTokenResponse, GetTokenQuery, CreateTokenRequest, CreateTokenResponse } from "../../types/Token";


class TokenService extends BaseService {
    constructor(token: string) {
        super(token);
    }

    public async getTokens(query: GetTokenQuery): Promise<GetTokenResponse> {
        const url = buildGetTokensUrl(`${this.api_url}/oauth/tokens`, query);
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${this.token}`,
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok) {
            throw new Error(`Error fetching tokens: ${response.statusText}`);
        }
        const data: GetTokenResponse = await response.json();
        return data
    }

    public async deleteToken(tokenId: number): Promise<void> {
        const url = `${this.api_url}/oauth/tokens/${tokenId}`;
        const response = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${this.token}`,
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok) {
            throw new Error(`Error deleting token: ${response.statusText}`);
        }
    }

    public async getTokenbyTokenId(tokenId: number): Promise<Token> {
        const url = `${this.api_url}/oauth/tokens/${tokenId}`;
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${this.token}`,
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok) {
            throw new Error(`Error fetching token: ${response.statusText}`);
        }
        const data: Token = await response.json();
        return data;
    }

    public async createToken(description: string, duration: number): Promise<CreateTokenResponse> {
        const url = `${this.api_url}/oauth/token`;
        const body: CreateTokenRequest = {
            grant_type: 'create_token',
            duration,
            token_description: description,
            request_type: 'api',
            access_token: this.token,
        };
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${this.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });
        if (!response.ok) {
            throw new Error(`Error creating token: ${response.statusText}`);
        }
        const data: CreateTokenResponse = await response.json();
        return data;
    }
}

export default TokenService;

function buildGetTokensUrl(baseUrl: string, query: GetTokenQuery): string {
    const url = new URL(baseUrl);
    if (query.limit) url.searchParams.append('limit', query.limit.toString());
    if (query.offset) url.searchParams.append('offset', query.offset.toString());
    if (query.order_by) url.searchParams.append('order_by', query.order_by);
    if (query.order) url.searchParams.append('order', query.order);
    if (query.user_id) url.searchParams.append('user_id', query.user_id.toString());
    if (query.org_id) url.searchParams.append('org_id', query.org_id.toString());
    if (query.node_id) url.searchParams.append('node_id', query.node_id.toString());
    if (query.tombstoned !== undefined) url.searchParams.append('tombstoned', String(query.tombstoned));
    if (query.uid) url.searchParams.append('uid', query.uid);
    if (query.search) url.searchParams.append('search', query.search);
    return url.toString();
}
