// SidebarMenu.tsx
import React, { useState } from 'react'
import {
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import HomeIcon from '@mui/icons-material/Home'
import DevicesIcon from '@mui/icons-material/Devices'
import AppsIcon from '@mui/icons-material/Apps'
import { useNavigate } from 'react-router-dom'
import GroupWorkIcon from '@mui/icons-material/GroupWork'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'

const SidebarMenu: React.FC = () => {
    const [open, setOpen] = useState(false)
    const navigate = useNavigate()

    const handleDrawerOpen = () => {
        setOpen(true)
    }

    const handleDrawerClose = () => {
        setOpen(false)
    }

    const handleNavigation = (path: string) => {
        navigate(path)
        handleDrawerClose()
    }

    return (
        <div>
            <IconButton
                color="secondary"
                onClick={handleDrawerOpen}
                style={{ position: 'absolute', top: '6px', left: '28px' }}
            >
                <MenuIcon />
            </IconButton>
            <Drawer
                anchor="left"
                open={open}
                onClose={handleDrawerClose}
                // style={{ width: '250px' }}
            >
                <List>
                    <ListItem onClick={() => handleNavigation('/home')}>
                        <ListItemIcon>
                            <HomeIcon  sx={{ color: 'text.primary' }} />
                        </ListItemIcon>
                        <ListItemText primary="Home" />
                    </ListItem>
                    <ListItem onClick={() => handleNavigation('/nodes')}>
                        <ListItemIcon>
                            <DevicesIcon  sx={{ color: 'text.primary' }} />
                        </ListItemIcon>
                        <ListItemText primary="Nodes" />
                    </ListItem>
                    <ListItem onClick={() => handleNavigation('/apps')}>
                        <ListItemIcon>
                            <AppsIcon  sx={{ color: 'text.primary' }} />
                        </ListItemIcon>
                        <ListItemText primary="Apps" />
                    </ListItem>
                    <ListItem onClick={() => handleNavigation('/clusters')}>
                        <ListItemIcon>
                            <GroupWorkIcon sx={{ color: 'text.primary' }}  />
                        </ListItemIcon>
                        <ListItemText primary="Clusters" />
                    </ListItem>
                    <ListItem onClick={() => handleNavigation('/users-and-tokens')}>
                        <ListItemIcon>
                            <AdminPanelSettingsIcon sx={{ color: 'text.primary' }} />
                        </ListItemIcon>
                        <ListItemText primary="Users and Tokens" />
                    </ListItem>
                </List>
            </Drawer>
        </div>
    )
}

export default SidebarMenu
