import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TableFooter,
    TablePagination,
    TableSortLabel,
    IconButton,
    TextField,
    InputAdornment,
    Button,
    Box,
    Icon,
    Chip,
    colors,
} from '@mui/material';
import { User, UserRoleEnum, AccountTypeEnum, CreationMethodEnum } from '../../types/User';
import UserService from '../../libs/ambient_api/UserService';
import SearchIcon from '@mui/icons-material/Search';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import CodeIcon from '@mui/icons-material/Code';
import FaceIcon from '@mui/icons-material/Face';


interface UsersTableProps {
    token: string;
}

const UsersTable: React.FC<UsersTableProps> = ({ token }) => {
    const [users, setUsers] = useState<User[]>([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [totalUsers, setTotalUsers] = useState(0);
    const [order, setOrder] = useState<'asc' | 'desc'>('asc');
    const [orderBy, setOrderBy] = useState<keyof User>('first_name');
    const [search, setSearch] = useState('');
    const [searchClicked, setSearchClicked] = useState(false);
    const [refresh, setRefresh] = useState(false);

    // Fetch users when the component mounts
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const offset = page * rowsPerPage;
                const userService = new UserService(token);
                const data = await userService.getUsers(offset, rowsPerPage, orderBy, order, search);
                setUsers(data.results);
                setTotalUsers(data.count); // Assuming the API returns the total count of users
            } catch (error) {
                console.error('Failed to fetch users:', error);
            }
        };

        fetchUsers();
    }, [page, rowsPerPage, order, orderBy, refresh]);

    // handle change in search
    useEffect(() => {
        setPage(0);
        setRefresh(!refresh);
    }
    , [search]);

    const handleSort = (key: keyof User) => {
        const isAsc = orderBy === key && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(key);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // function to build chip component based on role
    const buildRoleChip = (role: UserRoleEnum) => {
        switch (role) {
            case UserRoleEnum.ADMIN:
                return <Chip color='primary' icon={<AdminPanelSettingsIcon />} label="Admin" />
            case UserRoleEnum.DEVELOPER:
                return <Chip color='secondary' icon={<CodeIcon />} label="Developer" />
            case UserRoleEnum.VIEWER:
                return <Chip color='info' icon={<FaceIcon />} label="Viewer" />
            default:
                return null;
        }
    }

    return (
        <TableContainer component={Paper} sx={{ width: { xs: '500px', md: '800px' } }}>
            <Table aria-label="users table">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'name'}
                                direction={orderBy === 'name' ? order : 'asc'}
                                onClick={() => handleSort('name')}
                            >
                                Name
                            </TableSortLabel>
                            {
                                searchClicked ? (
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <TextField
                                            value={search}
                                            onChange={(e) => setSearch(e.target.value)}
                                            placeholder="Search..."
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            sx={{ color: 'text.secondary' }}
                                                            onClick={() => {
                                                                setSearchClicked(false);
                                                            }}
                                                        >
                                                            <SearchIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Box>

                                ) : (

                                <IconButton
                                    sx={{ color: 'text.secondary' }}
                                    onClick={() => setSearchClicked(true)}
                                >
                                    <SearchIcon />
                                </IconButton>
                                )
                            }
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'email'}
                                direction={orderBy === 'email' ? order : 'asc'}
                                onClick={() => handleSort('email')}
                            >
                                Email
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'role'}
                                direction={orderBy === 'role' ? order : 'asc'}
                                onClick={() => handleSort('role')}
                            >
                                Role
                            </TableSortLabel>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map((user) => (
                        <TableRow key={user.id}>
                            <TableCell component="th" scope="row">
                                {user.name}
                            </TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>{buildRoleChip(user.role)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            colSpan={4}
                            count={totalUsers}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
};

export default UsersTable;