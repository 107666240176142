import {
    Button,
    Grid,
    Typography,
    Paper,
    Box,
    IconButton,
    AppBar,
    Chip,
    Tooltip,
    ButtonGroup,
    Card,
} from '@mui/material'

import TopToolBar from '../../components/AppToolBar'
import UsersPanel from './UsersPanel'
import TokensPanel from './TokensPanel'
import { useNavigate } from 'react-router-dom'


const UsersAndTokensPage: React.FC = () => {
    const navigate = useNavigate()
    const token = localStorage.getItem('access_token')
    if (!token) {
        navigate('/login')
    }

    return (
        <Box>
            <TopToolBar />
            <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                style={{ marginTop: '10px' }}
            >
                <Grid item xs={12}>
                    <UsersPanel token={token!}/>
                </Grid>
                <Grid item xs={12}>
                    <TokensPanel token={token!}/>
                </Grid>
            </Grid>
        </Box>
    )
}

export default UsersAndTokensPage