import ResourceTypeEnum from './ResourceTypeEnum'
import BaseInterface from './BaseInterface'

export enum UserRoleEnum {
    ADMIN = 'admin',
    DEVELOPER = 'developer',
    VIEWER = 'viewer',
    SERVICE_ACCOUNT = 'service_account',
}

export enum AccountTypeEnum {
    INDIVIDUAL = 'individual',
    ORGANIZATION = 'organization',
}

export enum CreationMethodEnum {
    NEW_ACCOUNT = 'new_account',
    INVITE = 'invite',
}

export interface UserPreferences {
    dark_mode: boolean
}

export interface User extends BaseInterface {
    account_type: AccountTypeEnum
    creation_method: CreationMethodEnum
    email: string
    first_name: string
    last_name: string
    resource_type: ResourceTypeEnum
    role: UserRoleEnum
    preferences?: UserPreferences

    auth_provider_uid: string | null
    username: string | null
    phone_number: string | null
    is_super_admin: boolean
}

interface OwnerUserParams {
    organization: string
}

interface InviteUserParams {
    invite_token: string
}

export interface UserCreateRequest {
    email: string
    first_name: string
    last_name: string
    phone_number: string
    role: string
    account_type: string
    creation_method: string
    username: string
    auth0_uid: string

    // Optional
    owner_user_params?: OwnerUserParams
    invite_user_params?: InviteUserParams
}

export interface GetUsersResponse {
    count: number
    timestamp: string
    results: User[]
}
